import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";

@Module
export default class BodyModule extends VuexModule {
  mockItems = [
    {
      value1: "1",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "2",
      value2: "2",
      value3: "3",
      value4: 0,
      value5: "5",
      value6: "6",
    },
    {
      value1: "3",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "4",
      value2: "2",
      value3: "3",
      value4: 2,
      value5: "5",
      value6: "6",
    },
    {
      value1: "5",
      value2: "2",
      value3: "3",
      value4: 2,
      value5: "5",
      value6: "6",
    },
    {
      value1: "6",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "7",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "8",
      value2: "2",
      value3: "3",
      value4: 0,
      value5: "5",
      value6: "6",
    },
    {
      value1: "9",
      value2: "2",
      value3: "3",
      value4: 2,
      value5: "5",
      value6: "6",
    },
    {
      value1: "10",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "11",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "12",
      value2: "2",
      value3: "3",
      value4: 2,
      value5: "5",
      value6: "6",
    },
    {
      value1: "13",
      value2: "2",
      value3: "3",
      value4: 2,
      value5: "5",
      value6: "6",
    },
    {
      value1: "14",
      value2: "2",
      value3: "3",
      value4: 2,
      value5: "5",
      value6: "6",
    },
    {
      value1: "15",
      value2: "2",
      value3: "3",
      value4: 2,
      value5: "5",
      value6: "6",
    },
    {
      value1: "16",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "17",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "18",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "19",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "20",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "21",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "22",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "23",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "24",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "25",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "26",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "27",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "28",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "29",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "30",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "31",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },

    //---
    {
      value1: "1",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "2",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "3",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "4",
      value2: "2",
      value3: "3",
      value4: 2,
      value5: "5",
      value6: "6",
    },
    {
      value1: "5",
      value2: "2",
      value3: "3",
      value4: 2,
      value5: "5",
      value6: "6",
    },
    {
      value1: "6",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "7",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "8",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "9",
      value2: "2",
      value3: "3",
      value4: 2,
      value5: "5",
      value6: "6",
    },
    {
      value1: "10",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "11",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "12",
      value2: "2",
      value3: "3",
      value4: 2,
      value5: "5",
      value6: "6",
    },
    {
      value1: "13",
      value2: "2",
      value3: "3",
      value4: 2,
      value5: "5",
      value6: "6",
    },
    {
      value1: "14",
      value2: "2",
      value3: "3",
      value4: 2,
      value5: "5",
      value6: "6",
    },
    {
      value1: "15",
      value2: "2",
      value3: "3",
      value4: 2,
      value5: "5",
      value6: "6",
    },
    {
      value1: "16",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "17",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "18",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "19",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "20",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "21",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "22",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "23",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "24",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "25",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "26",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "27",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "28",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "29",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "30",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "31",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    //---
    {
      value1: "1",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "2",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "3",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "4",
      value2: "2",
      value3: "3",
      value4: 2,
      value5: "5",
      value6: "6",
    },
    {
      value1: "5",
      value2: "2",
      value3: "3",
      value4: 2,
      value5: "5",
      value6: "6",
    },
    {
      value1: "6",
      value2: "2",
      value3: "3",
      value4: 0,
      value5: "5",
      value6: "6",
    },
    {
      value1: "7",
      value2: "2",
      value3: "3",
      value4: 0,
      value5: "5",
      value6: "6",
    },
    {
      value1: "8",
      value2: "2",
      value3: "3",
      value4: 0,
      value5: "5",
      value6: "6",
    },
    {
      value1: "9",
      value2: "2",
      value3: "3",
      value4: 2,
      value5: "5",
      value6: "6",
    },
    {
      value1: "10",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "11",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "12",
      value2: "2",
      value3: "3",
      value4: 2,
      value5: "5",
      value6: "6",
    },
    {
      value1: "13",
      value2: "2",
      value3: "3",
      value4: 2,
      value5: "5",
      value6: "6",
    },
    {
      value1: "14",
      value2: "2",
      value3: "3",
      value4: 2,
      value5: "5",
      value6: "6",
    },
    {
      value1: "15",
      value2: "2",
      value3: "3",
      value4: 2,
      value5: "5",
      value6: "6",
    },
    {
      value1: "16",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "17",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "18",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "19",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "20",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "21",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "22",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "23",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "24",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "25",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "26",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "27",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "28",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "29",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "30",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "31",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    //---
    {
      value1: "1",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "2",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "3",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "4",
      value2: "2",
      value3: "3",
      value4: 2,
      value5: "5",
      value6: "6",
    },
    {
      value1: "5",
      value2: "2",
      value3: "3",
      value4: 2,
      value5: "5",
      value6: "6",
    },
    {
      value1: "6",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "7",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "8",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "9",
      value2: "2",
      value3: "3",
      value4: 2,
      value5: "5",
      value6: "6",
    },
    {
      value1: "10",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "11",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "12",
      value2: "2",
      value3: "3",
      value4: 2,
      value5: "5",
      value6: "6",
    },
    {
      value1: "13",
      value2: "2",
      value3: "3",
      value4: 2,
      value5: "5",
      value6: "6",
    },
    {
      value1: "14",
      value2: "2",
      value3: "3",
      value4: 2,
      value5: "5",
      value6: "6",
    },
    {
      value1: "15",
      value2: "2",
      value3: "3",
      value4: 2,
      value5: "5",
      value6: "6",
    },
    {
      value1: "16",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "17",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "18",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "19",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "20",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "21",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "22",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "23",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "24",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "25",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "26",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "27",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "28",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "29",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "30",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "31",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    //---
    {
      value1: "1",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "2",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "3",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "4",
      value2: "2",
      value3: "3",
      value4: 2,
      value5: "5",
      value6: "6",
    },
    {
      value1: "5",
      value2: "2",
      value3: "3",
      value4: 2,
      value5: "5",
      value6: "6",
    },
    {
      value1: "6",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "7",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "8",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "9",
      value2: "2",
      value3: "3",
      value4: 2,
      value5: "5",
      value6: "6",
    },
    {
      value1: "10",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "11",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "12",
      value2: "2",
      value3: "3",
      value4: 2,
      value5: "5",
      value6: "6",
    },
    {
      value1: "13",
      value2: "2",
      value3: "3",
      value4: 2,
      value5: "5",
      value6: "6",
    },
    {
      value1: "14",
      value2: "2",
      value3: "3",
      value4: 2,
      value5: "5",
      value6: "6",
    },
    {
      value1: "15",
      value2: "2",
      value3: "3",
      value4: 2,
      value5: "5",
      value6: "6",
    },
    {
      value1: "16",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "17",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "18",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "19",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "20",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "21",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "22",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "23",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
    {
      value1: "24",
      value2: "2",
      value3: "3",
      value4: 1,
      value5: "5",
      value6: "6",
    },
  ];
}
