
import { defineComponent, onMounted } from "vue";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";


export default defineComponent({
  name: "app",
  setup() {
    const store = useStore();

    onMounted(() => {
      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
       */
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);
    });
    document.title = "bem-document-demo คลังสินค้า";
  },

  watch: {
    $route(to, from) {
      const getToken = localStorage.getItem("belucaCodex");
      if (!getToken) {
        if (
          to.name === "login" ||
          to.name === "register" ||
          to.name === "public"
        ) {
          // this.$router.push("/public");
        } else {
          this.$router.push("/login");
          //หน้าแรกวิ่งตรงนี้
        }
      }
    },
  },

  methods: {
    getPopupContainer(el, dialogContext) {
      if (dialogContext) {
        return dialogContext.getDialogWrap();
      } else {
        return document.body;
      }
    },
  },
});
