import {createI18n} from "vue-i18n";

const messages = {
    en: {
        dashboard: "Dashboard",
        todaysales: "today's sales",
        customerbills: "number of customer bills",
        no: "No.",
        add: "Add",
        search: "Search",
        save: "Save",
        cancel: "Cancel",
        type: "Type",
        selling: "Sell",
        setting: "Setting",
        salessummary: "Sales Summary",
        importdatafromexcel: ' Importdata From Excel',



        // branch
        branchcode: "Branch code",
        initials: "Initials",
        branchname: "Branch name",
        addbranch: "Add Branch",
        editbranch: "Edit Branch",
        // wh1
        warehousetype: "Warehouse Type",
        warehouses: 'Warehouse',
        warehousecode: 'Warehouse Code',
        addwarehouse: 'Add Warehouse',
        company: 'Company',
        telephone: 'tel',
        fax: 'Fax',
        latitude: 'latitude',
        longitude: 'longitude',
        contact: 'Contact',
        name: 'Name',
        position: 'Position',
        email: "Email",
        editwarehouse: 'Edit Warehouse',
        warehousename: 'Warehouse Name',
        address: 'Address',
        subdistrict:'subdistrict',
        district:'district',
        province:'province',
        postcode:'postcode',


        //van
        vancode: 'Van Code',
        vanname: 'Van Name',
        addvancode: 'Add Van',
        selectbranch: 'Select Branch',
        editvancode: 'Edit Van',
        //unit
        unitcode:'Unit Code',
        unitname:'Unit Name',
        addunit:'Add Unit',
        editunit:'Edit Unit',

        //wh2
        mainproductcode: 'Mainproduct Code',
        mainproductname: 'Mainproduct Name',
        addmainproductname: 'Add Mainproduct',
        editmainproduct: 'Edit Main Product ',

        //WH2 sub
        producttypecode: 'Product Type Code',
        producttypename: 'Product Type Name',
        addproducttype: 'Add Product Type',
        selectmainproduct: 'Select Main Product ',
        editproducttype: 'Edit Product Type',

        //WH4 
        productcode: 'Product Code',
        productname:'Product Name',
        cost: 'Cost',
        status:'Status',
        adddproduct: 'Add product',
        detailproduct:'Detail Product',
        barcode: 'Barcode',

        width: ' width ',
        long: ' long ',
        high: ' high ',
        volume: ' volume ',
        weight: ' weight ',
        score: ' score ',





        warehouse: 'Manage Warehouse',
        customer: 'Customer',
        branch: 'Manage Branch',
        managevan: 'Manage VAN',
        manageunit: 'Manage UNIT',
        managemainproduct: 'Manage Main Product',
        managesubproduct: 'Manage Sub Product',
        manageproducttype: 'Manage Product Type',
        addproduct: 'Manage Products',

        addcustomer: 'Customers',
        adddiscount: 'Discount',
        addroutes: 'Manage Routes',
        addbusinesstype: 'Manage Business Types',
        addsector: 'Manage Sectors',
        addzone: 'Manage Zones',


        pages: "Pages",
        SetAccountPeriod: "About Us",
        invoice: "Invoice",
        faq: "FAQ",
        wizard: "Wizard",
        pricing: "Pricing",
        account: "Account",
        overview: "Overview",
        settings: "Settings",
        security: "Security",
        auditLogs: "Audit Logs",
        activity: "Activity",
        authentication: "Authentication",
        base: "Base",
        signIn: "Sign-in",
        signUp: "Sign-up",
        passwordReset: "Password Reset",
        extended: "Extended",
        multiStep: "Multi-steps",
        freeTrial: "Free Trial",
        comingSoon: "Coming Soon",
        general: "General",
        welcome: "Welcome",
        verifyEmail: "Verify Email",
        passwordConfirmation: "Password Confirmation",
        closeAccount: "Close Account",
        error404: "Error 404",
        error500: "Error 500",
        passwordChange: "Password Change",
        subscriptions: "Subscriptions",
        getStarted: "Getting Started",
        subscriptionList: "Subscription List",
        addSubscription: "Add Subscription",
        viewSubscription: "View Subscription",
        chat: "Chat",
        privateChat: "Private Chat",
        groupChat: "Group Chat",
        drawerChat: "Drawer Chat",
        components: "Components",
        documentation: "Documentation",
        layoutBuilder: "Layout Builder",
        changelog: "Changelog",
        calendar: "Calendar"
    },
    es: {
        dashboard: "Tablero",
        pages: "Páginas",
        SetAccountPeriod: "Sobre Nosotros",
        invoice: "Factura",
        faq: "Preguntas Más Frecuentes",
        wizard: "Mago",
        pricing: "Precios",
        account: "Cuenta",
        overview: "Visión General",
        settings: "Ajustes",
        security: "Secuiridad",
        auditLogs: "Registros De Auditoría",
        activity: "Actividad",
        authentication: "Autenticación",
        base: "Base",
        signIn: "Registrarse",
        signUp: "Inscribirse",
        passwordReset: "Restablecimiento De Contraseña",
        extended: "Extendido",
        multiStep: "Multi-Pasos",
        freeTrial: "Prueba Gratis",
        comingSoon: "Próximamente, En Breve, Pronto",
        general: "General",
        welcome: "Bienvenido",
        verifyEmail: "Verificar El Correo Electrónico",
        passwordConfirmation: "Confirmación De Contraseña",
        closeAccount: "Cerrar Cuenta",
        error404: "Error 404",
        error500: "Error 500",
        
        passwordChange: "Cambio De Contraseña",
        subscriptions: "Suscripciones",
        getStarted: "Empezando",
        subscriptionList: "Lista De Suscripción",
        addSubscription: "Añadir Suscripción",
        viewSubscription: "Suscripción",
        chat: "Chat",
        privateChat: "Chat Privado",
        groupChat: "Grupo De Chat",
        drawerChat: "Chat De Cajones",
        components: "Componentes",
        documentation: "Documentación",
        layoutBuilder: "Constructor De Diseño",
        changelog: "Log",
        calendar: "Calendario"
    },
    th: {
        dashboard: "แดชบอร์ด",
        todaysales: "ยอดขายวันนี้",
        customerbills: "จำนวนบิลลูกค้า",
        no: "ลำดับที่",
        add: "เพิ่มข้อมูล",
        search: "ค้นหา",
        save: "บันทึก",
        cancel: "ยกเลิก",
        editbranch: "แก้ไข สาขา",
        type: "ประเภท",
        selling: "ขาย",
        setting: "ตั้งค่า",
        salessummary: "สรุปการขาย",


        // branch
        branchcode: "รหัสสาขา",
        initials: "ชื่อย่อ",
        branchname: "ชื่อสาขา",
        addbranch: "เพิ่มสาขา",
        // wh1
        warehousetype: "ประเภทคลัง",
        warehouses: 'คลังขายสินค้าด้วย',
        warehousecode: 'รหัสคลัง',
        warehousename: 'ชื่อคลัง',
        addwarehouse: 'เพิ่มคลังสินค้า',
        company: 'บริษัท',
        telephone: 'โทรศัพท์',
        fax: 'แฟกซ์',
        latitude: 'ละติจูด',
        longitude: 'ลองติจูด',
        contact: 'ผู้ติดต่อ',
        name: 'ชื่อ',
        position: 'ตำแหน่ง',
        email: "อีเมล",
        editwarehouse: 'แก้ไขคลังสินค้า',
        address: 'ที่อยู่',
        subdistrict:'ตำบล',
        district:'อำเภอ',
        province:'จังหวัด',
        postcode:'รหัสไปรษณีย์',

        
        //van
        vancode: 'รหัสVAN',
        vanname: 'ชื่อVan',
        addvancode: 'เพิ่ม Van',
        selectbranch: 'เลือกสาขา',
        editvancode: 'แก้ไข Van',

        //unit
        unitcode:'รหัสหน่วยนับ',
        unitname:'ชื่อหน่วยนับ',
        addunit:'เพิ่มหน่วยสินค้า',
        editunit:'แก้ไขหน่วยสินค้า',
        
        //WH2
        mainproductcode: 'รหัสกลุ่มสินค้าหลัก',
        mainproductname: 'ชื่อกลุ่มสินค้าหลัก',
        addmainproductname: 'เพิ่มกลุ่มสินค้าหลัก ',
        editmainproduct: 'แก้ไขกลุ่มสินค้าหลัก ',

        //WH2 sub
        producttypecode: 'รหัสประเภทสินค้า',
        producttypename: ' ชื่อประเภทสินค้า ',
        addproducttype: 'เพิ่มประเภทสินค้า',
        selectmainproduct: 'เลือกกลุ่มสินค้าหลัก ',
        editproducttype: 'แก้ไขประเภทสินค้า',

        //WH4 
        productcode: 'รหัสสินค้า',
        productname:'ชื่อสินค้า',
        cost: 'ราคาทุน',
        status:'สถานะ',
        adddproduct: 'เพิ่มสินค้า',
        detailproduct:'รายละเอียดสินค้า',
        barcode: 'บาร์โค้ด',
        mainproduct: 'กลุ่มสินค้าหลัก',
        producttype: ' ประเภทสินค้า ',

        width: ' กว้าง ',
        long: ' ยาว ',
        high: ' สูง ',
        volume: ' ปริมาตร ',
        weight: ' น้ำหนัก ',
        score: ' แต้ม ',
        // status: ' สถานะ ',





        




        
        



        






        warehouse: 'จัดการคลังสินค้า',
        importdatafromexcel: 'นำข้อมูลเข้าจาก excel',

        customer: 'ลูกค้า',
        branch: 'จัดการสาขา',
        managevan: 'จัดการ VAN',
        manageunit: 'จัดการหน่วยสินค้า',
        managemainproduct: 'จัดการกลุ่มสินค้าหลัก',
        managesubproduct: 'จัดการกลุ่มสินค้ารอง',
        manageproducttype: 'จัดการประเภทสินค้า',
        addproduct: 'จัดการสินค้า',

        addcustomer: 'เพิ่มลูกค้า',
        adddiscount: 'เพิ่มส่วนลดท้ายบิล',
        addroutes: 'เพิ่มสายวิ่ง',
        addbusinesstype: 'เพิ่มประเภทร้านค้า',
        addsector: 'เพิ่มเขต',
        addzone: 'เพิ่มภาค',


        pages: "Pages",
        SetAccountPeriod: "About Us",
        invoice: "Invoice",
        faq: "FAQ",
        wizard: "Wizard",
        pricing: "Pricing",
        account: "Account",
        overview: "Overview",
        settings: "Settings",
        security: "Security",
        auditLogs: "Audit Logs",
        activity: "Activity",
        authentication: "Authentication",
        base: "Base",
        signIn: "Sign-in",
        signUp: "Sign-up",
        passwordReset: "Password Reset",
        extended: "Extended",
        multiStep: "Multi-steps",
        freeTrial: "Free Trial",
        comingSoon: "Coming Soon",
        general: "General",
        welcome: "Welcome",
        verifyEmail: "Verify Email",
        passwordConfirmation: "Password Confirmation",
        closeAccount: "Close Account",
        error404: "Error 404",
        error500: "Error 500",
        passwordChange: "Password Change",
        subscriptions: "Subscriptions",
        getStarted: "Getting Started",
        subscriptionList: "Subscription List",
        addSubscription: "Add Subscription",
        viewSubscription: "View Subscription",
        chat: "Chat",
        privateChat: "Private Chat",
        groupChat: "Group Chat",
        drawerChat: "Drawer Chat",
        components: "Components",
        documentation: "Documentation",
        layoutBuilder: "Layout Builder",
        changelog: "Changelog",
        calendar: "Calendar"
    }
};

const i18n = createI18n({legacy: false, locale: "en", globalInjection: true, messages});

export default i18n;
