import axios from "axios";
import apiConfig from "../config/api";
import { Decode, Encode } from "@/services";

const apiInstance = {
  api: null,
};

export const createInstance = (context) => {
  // const { store, redirect } = context;
  apiInstance.context = context;
  const api = axios.create({
    baseURL: apiConfig.ENDPOINT,
  });

  api.interceptors.request.use((config) => {
    let token = localStorage.getItem("belucaCodex");
    let companyId = localStorage.getItem("companyId");
    if (token) {
      token = Decode.decode(token);
      token = token.replaceAll('"', "");
      config.headers.Authorization = `Bearer ${token}`; // custom api
      config.headers.company = companyId; // custom companyId
    }

    // store.commit("loading/start");
    // if (store.state.user.token) {
    // config.headers.Authorization = `Bearer 1234`; // custom api
    // }
    // if (store.state.company.selected) {
    //   config.headers.companyCode = store.state.company.selected.companyCode;
    // }
    // if (store.state.header.data) {
    //   config.headers = {
    //     ...config.headers,
    //     ...store.state.header.data
    //   };
    // }
    return config;
  });

  api.interceptors.response.use(
    (response) => {
      // store.commit("loading/end");
      // console.log({ response });
      return response;
    },
    (error) => {
      // store.commit("loading/end");
      // if (error.response.status === 401) {
      // store.commit("user/resetData");
      // store.commit("user/resetToken");
      // redirect("/login");
      window.location.href = "/#/public";

      // }
      return error;
    }
  );
  apiInstance.api = api;
};

export default apiInstance;
