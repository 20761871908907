import {createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw} from "vue-router";

const routes: Array < RouteRecordRaw > = [
    // saraban


    // login
    {
        path: "/login",
        name: "login",
        component: () => import ("@/views/Login.vue")
    },
    {
        path: "/public",
        name: "public",
        component: () => import ("@/views/Public.vue")
    },
    {
        path: "/register",
        name: "register",
        component: () => import ("@/views/Register.vue")
    },
    {
        path: "/select-company",
        name: "select-company",
        component: () => import ("@/views/SelectCompany.vue")
    }, {
        path: "/",
        redirect: "/saraban5",
        component: () => import ("@/layout/Layout.vue"),
        children: [
            // //login
            // {
            // path: "/login",
            // name: "login",
            // component: () => import("@/views/Login.vue"),
            // },

            // dashboard
            {
                path: "/dashboard",
                name: "dashboard",
                component: () => import ("@/views/Dashboard.vue")
            },

            // saraban
            {
                path: "/saraban5",
                name: "saraban5",
                component: () => import ("@/views/saraban/saraban5/index.vue")
            },
            {
                path: "/saraban5/new",
                name: "saraban5-new",
                component: () => import ("@/views/saraban/saraban5/new.vue")
            },
            {
                path: "/saraban5/edit",
                name: "saraban5-edit",
                component: () => import ("@/views/saraban/saraban5/edit.vue")
            }, {
                path: "/saraban5/view",
                name: "saraban5-view",
                component: () => import ("@/views/saraban/saraban5/view.vue")
            },


            // manage-system
            {
                path: "/manage-system/op1",
                name: "OP1",
                component: () => import ("@/views/manage-system/OP1/index.vue")
            }, {
                path: "/manage-system/op1/new",
                name: "OP1-new",
                component: () => import ("@/views/manage-system/OP1/new.vue")
            }, {
                path: "/manage-system/op1/edit",
                name: "OP1-edit",
                component: () => import ("@/views/manage-system/OP1/edit.vue")
            },
            // ---
            {
                path: "/manage-system/op2",
                name: "OP2",
                component: () => import ("@/views/manage-system/OP2/index.vue")
            }, {
                path: "/manage-system/op2/list",
                name: "OP2-list",
                component: () => import ("@/views/manage-system/OP2/list.vue")
            }, {
                path: "/manage-system/op2/new",
                name: "OP2-new",
                component: () => import ("@/views/manage-system/OP2/new.vue")
            }, {
                path: "/manage-system/op2/edit",
                name: "OP2-edit",
                component: () => import ("@/views/manage-system/OP2/edit.vue")
            },
            // ---
            {
                path: "/manage-system/op3",
                name: "OP3",
                component: () => import ("@/views/manage-system/OP3/index.vue")
            },

            // warehouse
            {
                path: "warehouse/VAN",
                name: "VAN",
                component: () => import ("@/views/warehouse/VAN/index.vue")
            }, {
                path: "warehouse/VAN/new",
                name: "VAN-new",
                component: () => import ("@/views/warehouse/VAN/new.vue")
            }, {
                path: "warehouse/VAN/edit",
                name: "VAN-edit",
                component: () => import ("@/views/warehouse/VAN/edit.vue")
            },
            // ---
            {
                path: "warehouse/UNIT",
                name: "UNIT",
                component: () => import ("@/views/warehouse/UNIT/index.vue")
            }, {
                path: "warehouse/UNIT/new",
                name: "UNIT-new",
                component: () => import ("@/views/warehouse/UNIT/new.vue")
            }, {
                path: "warehouse/UNIT/edit",
                name: "UNIT-edit",
                component: () => import ("@/views/warehouse/UNIT/edit.vue")
            },
            // ---
            {
                path: "warehouse/RUNNING",
                name: "RUNNING",
                component: () => import ("@/views/warehouse/RUNNING/index.vue")
            }, {
                path: "warehouse/RUNNING/new",
                name: "RUNNING-new",
                component: () => import ("@/views/warehouse/RUNNING/new.vue")
            }, {
                path: "warehouse/RUNNING/edit",
                name: "RUNNING-edit",
                component: () => import ("@/views/warehouse/RUNNING/edit.vue")
            },
            // ---
            {
                path: "warehouse/BRANCH",
                name: "BRANCH",
                component: () => import ("@/views/warehouse/BRANCH/index.vue")
            }, {
                path: "warehouse/BRANCH/new",
                name: "BRANCH-new",
                component: () => import ("@/views/warehouse/BRANCH/new.vue")
            }, {
                path: "warehouse/BRANCH/edit",
                name: "BRANCH-edit",
                component: () => import ("@/views/warehouse/BRANCH/edit.vue")
            },
            // ---
            {
                path: "warehouse/WH1",
                name: "WH1",
                component: () => import ("@/views/warehouse/WH1/index.vue")
            }, {
                path: "warehouse/WH1/new",
                name: "WH1-new",
                component: () => import ("@/views/warehouse/WH1/new.vue")
            }, {
                path: "warehouse/WH1/edit",
                name: "WH1-edit",
                component: () => import ("@/views/warehouse/WH1/edit.vue")
            },
            // ---
            {
                path: "warehouse/WH2",
                name: "WH2",
                component: () => import ("@/views/warehouse/WH2/index.vue")
            }, {
                path: "warehouse/WH2/new",
                name: "WH2-new",
                component: () => import ("@/views/warehouse/WH2/new.vue")
            }, {
                path: "warehouse/WH2/edit",
                name: "WH2-edit",
                component: () => import ("@/views/warehouse/WH2/edit.vue")
            },
            // ---
            {
                path: "warehouse/WH2SUB",
                name: "WH2SUB",
                component: () => import ("@/views/warehouse/WH2SUB/index.vue")
            }, {
                path: "warehouse/WH2SUB/new",
                name: "WH2SUB-new",
                component: () => import ("@/views/warehouse/WH2SUB/new.vue")
            }, {
                path: "warehouse/WH2SUB/edit",
                name: "WH2SUB-edit",
                component: () => import ("@/views/warehouse/WH2SUB/edit.vue")
            },
            // ---
            {
                path: "warehouse/WH3",
                name: "WH3",
                component: () => import ("@/views/warehouse/WH3/index.vue")
            }, {
                path: "warehouse/WH3/new",
                name: "WH3-new",
                component: () => import ("@/views/warehouse/WH3/new.vue")
            }, {
                path: "warehouse/WH3/edit",
                name: "WH3-edit",
                component: () => import ("@/views/warehouse/WH3/edit.vue")
            },
            // ---
            {
                path: "warehouse/WH4",
                name: "WH4",
                component: () => import ("@/views/warehouse/WH4/index.vue")
            }, {
                path: "warehouse/WH4/new",
                name: "WH4-new",
                component: () => import ("@/views/warehouse/WH4/new.vue")
            }, {
                path: "warehouse/WH4/edit",
                name: "WH4-edit",
                component: () => import ("@/views/warehouse/WH4/edit.vue")
            },
            // ---
            {
                path: "warehouse/WH5",
                name: "WH5",
                component: () => import ("@/views/warehouse/WH5/index.vue")
            }, {
                path: "warehouse/WH5/new",
                name: "WH5-new",
                component: () => import ("@/views/warehouse/WH5/new.vue")
            }, {
                path: "warehouse/WH5/edit",
                name: "WH5-edit",
                component: () => import ("@/views/warehouse/WH5/edit.vue")
            },
            // ---
            {
                path: "warehouse/WH6-1",
                name: "WH6-1",
                component: () => import ("@/views/warehouse/WH6-1/index.vue")
            }, {
                path: "warehouse/WH6-1/new",
                name: "WH6-1-new",
                component: () => import ("@/views/warehouse/WH6-1/new.vue")
            }, {
                path: "warehouse/WH6-1/edit",
                name: "WH6-1-edit",
                component: () => import ("@/views/warehouse/WH6-1/edit.vue")
            },
            // ---
            {
                path: "warehouse/WH6-2",
                name: "WH6-2",
                component: () => import ("@/views/warehouse/WH6-2/index.vue")
            },
            // {
            // path: "warehouse/WH6-2/new",
            // name: "WH6-2-new",
            // component: () => import("@/views/warehouse/WH6-2/new.vue"),
            // },
            // {
            // path: "warehouse/WH6-2/edit",
            // name: "WH6-2-edit",
            // component: () => import("@/views/warehouse/WH6-2/edit.vue"),
            // },
            // ---
            {
                path: "warehouse/WH6-3",
                name: "WH6-3",
                component: () => import ("@/views/warehouse/WH6-3/index.vue")
            }, {
                path: "warehouse/WH6-3/new",
                name: "WH6-3-new",
                component: () => import ("@/views/warehouse/WH6-3/new.vue")
            }, {
                path: "warehouse/WH6-3/edit",
                name: "WH6-3-edit",
                component: () => import ("@/views/warehouse/WH6-3/edit.vue")
            },
            // ---
            {
                path: "warehouse/WH6-4",
                name: "WH6-4",
                component: () => import ("@/views/warehouse/WH6-4/index.vue")
            },
            // ---
            {
                path: "warehouse/WH7",
                name: "WH7",
                component: () => import ("@/views/warehouse/WH7/index.vue")
            }, {
                path: "warehouse/WH7/list",
                name: "WH7-list",
                component: () => import ("@/views/warehouse/WH7/list.vue")
            },

            // purchase
            {
                path: "/purchase/PU1",
                name: "purchase-pu1",
                component: () => import ("@/views/purchase/PU1/index.vue")
            }, {
                path: "/purchase/PU1/new",
                name: "purchase-pu1-new",
                component: () => import ("@/views/purchase/PU1/new.vue")
            }, {
                path: "/purchase/PU1/edit",
                name: "purchase-pu1-edit",
                component: () => import ("@/views/purchase/PU1/edit.vue")
            },
            // --
            {
                path: "/purchase/PU2-1",
                name: "purchase-pu2-1",
                component: () => import ("@/views/purchase/PU2-1/index.vue")
            }, {
                path: "/purchase/PU2-1/new",
                name: "purchase-pu2-1-new",
                component: () => import ("@/views/purchase/PU2-1/new.vue")
            }, {
                path: "/purchase/PU2-1/edit",
                name: "purchase-pu2-1-edit",
                component: () => import ("@/views/purchase/PU2-1/edit.vue")
            },
            // --
            {
                path: "/purchase/PU2-2",
                name: "purchase-pu2-2",
                component: () => import ("@/views/purchase/PU2-2/index.vue")
            },
            // --
            {
                path: "/purchase/PU2-3",
                name: "purchase-pu2-3",
                component: () => import ("@/views/purchase/PU2-3/index.vue")
            }, {
                path: "/purchase/PU2-3/productIntoWarehouse",
                name: "purchase-pu2-3-receive",
                component: () => import ("@/views/purchase/PU2-3/productIntoWarehouse.vue")
            },
            // --
            {
                path: "/purchase/PU2-4",
                name: "purchase-pu2-4",
                component: () => import ("@/views/purchase/PU2-4/index.vue")
            },

            // sale
            {
                path: "/sale/SA1",
                name: "sale-SA1",
                component: () => import ("@/views/sale/SA1/index.vue")
            }, {
                path: "/sale/SA1/new",
                name: "sale-SA1-new",
                component: () => import ("@/views/sale/SA1/new.vue")
            }, {
                path: "/sale/SA1/edit",
                name: "sale-SA1-edit",
                component: () => import ("@/views/sale/SA1/edit.vue")
            },
            // --
            {
                path: "/sale/SA2-1",
                name: "sale-SA2-1",
                component: () => import ("@/views/sale/SA2-1/index.vue")
            }, {
                path: "/sale/SA2-1/new",
                name: "sale-SA2-1-new",
                component: () => import ("@/views/sale/SA2-1/new.vue")
            }, {
                path: "/sale/SA2-1/edit",
                name: "sale-SA2-1-edit",
                component: () => import ("@/views/sale/SA2-1/edit.vue")
            },
            // --
            {
                path: "/sale/SA2-2",
                name: "sale-SA2-2",
                component: () => import ("@/views/sale/SA2-2/index.vue")
            },
            // --
            {
                path: "/sale/SA3-1",
                name: "sale-SA3-1",
                component: () => import ("@/views/sale/SA3-1/index.vue")
            }, {
                path: "/sale/SA3-1/new",
                name: "sale-SA3-1-new",
                component: () => import ("@/views/sale/SA3-1/new.vue")
            }, {
                path: "/sale/SA3-1/edit",
                name: "sale-SA3-1-edit",
                component: () => import ("@/views/sale/SA3-1/edit.vue")
            },
            // --
            {
                path: "/sale/SA3-2",
                name: "sale-SA3-2",
                component: () => import ("@/views/sale/SA3-2/index.vue")
            },
            // --
            {
                path: "/sale/SA4-1",
                name: "sale-SA4-1",
                component: () => import ("@/views/sale/SA4-1/index.vue")
            }, {
                path: "/sale/SA4-1/new",
                name: "sale-SA4-1-new",
                component: () => import ("@/views/sale/SA4-1/new.vue")
            },
            // --
            {
                path: "/sale/SA4-2",
                name: "sale-SA4-2",
                component: () => import ("@/views/sale/SA4-2/index.vue")
            },
            // --
            {
                path: "/sale/SA5",
                name: "sale-SA5",
                component: () => import ("@/views/sale/SA5/index.vue")
            },
            // --
            {
                path: "/sale/daily-retail-summary",
                name: "sale-daily-retail-summary",
                component: () => import ("@/views/sale/SA6/dailyRetailSummary.vue")
            },
            // ---
            {
                path: "/sale/price-tag",
                name: "sale-price-tag",
                component: () => import ("@/views/sale/priceTag.vue")
            },
            // customer{
            {
                path: "/CUSTOMER/index",
                name: "CUSTOMER-index",
                component: () => import ("@/views/CUSTOMER/new_customer/index.vue")
            }, {
                path: "/CUSTOMER/new",
                name: "CUSTOMER-new",
                component: () => import ("@/views/CUSTOMER/new_customer/new.vue")
            }, {
                path: "/CUSTOMER/edit",
                name: "CUSTOMER-edit",
                component: () => import ("@/views/CUSTOMER/new_customer/edit.vue")
            }, {
                path: "/CUSTOMER/list/route",
                name: "CUSTOMER-list-route",
                component: () => import ("@/views/CUSTOMER/new_customer/route.vue")
            },
            // ------
            {
                path: "/CUSTOMER/discount/index",
                name: "CUSTOMER-discount-index",
                component: () => import ("@/views/CUSTOMER/discount/index.vue")
            }, {
                path: "/CUSTOMER/discount/new",
                name: "CUSTOMER-discount-new",
                component: () => import ("@/views/CUSTOMER/discount/new.vue")
            }, {
                path: "/CUSTOMER/discount/edit",
                name: "CUSTOMER-discount-edit",
                component: () => import ("@/views/CUSTOMER/discount/edit.vue")
            }, {
                path: "/CUSTOMER/store_type/index",
                name: "CUSTOMER-store_type-index",
                component: () => import ("@/views/CUSTOMER/store_type/index.vue")
            }, {
                path: "/CUSTOMER/store_type/new",
                name: "CUSTOMER-store_type-new",
                component: () => import ("@/views/CUSTOMER/store_type/new.vue")
            }, {
                path: "/CUSTOMER/store_type/edit",
                name: "CUSTOMER-store_type-edit",
                component: () => import ("@/views/CUSTOMER/store_type/edit.vue")
            }, {
                path: "/CUSTOMER/route/index",
                name: "CUSTOMER-route-index",
                component: () => import ("@/views/CUSTOMER/route/index.vue")
            }, {
                path: "/CUSTOMER/route/new",
                name: "CUSTOMER-route-new",
                component: () => import ("@/views/CUSTOMER/route/new.vue")
            }, {
                path: "/CUSTOMER/route/edit",
                name: "CUSTOMER-route-edit",
                component: () => import ("@/views/CUSTOMER/route/edit.vue")
            }, {
                path: "/CUSTOMER/sector/index",
                name: "CUSTOMER-sector-index",
                component: () => import ("@/views/CUSTOMER/sector/index.vue")
            }, {
                path: "/CUSTOMER/sector/new",
                name: "CUSTOMER-sector-new",
                component: () => import ("@/views/CUSTOMER/sector/new.vue")
            }, {
                path: "/CUSTOMER/sector/edit",
                name: "CUSTOMER-sector-edit",
                component: () => import ("@/views/CUSTOMER/sector/edit.vue")
            }, {
                path: "/CUSTOMER/zone/index",
                name: "CUSTOMER-zone-index",
                component: () => import ("@/views/CUSTOMER/zone/index.vue")
            }, {
                path: "/CUSTOMER/zone/new",
                name: "CUSTOMER-zone-new",
                component: () => import ("@/views/CUSTOMER/zone/new.vue")
            }, {
                path: "/CUSTOMER/zone/edit",
                name: "CUSTOMER-zone-edit",
                component: () => import ("@/views/CUSTOMER/zone/edit.vue")
            },


            // transfer-product
            {
                path: "/transfer-product/TP1",
                name: "transfer-product-TP1",
                component: () => import ("@/views/transfer-product/TP1/index.vue")
            }, {
                path: "/transfer-product/TP1/new",
                name: "transfer-product-TP1-new",
                component: () => import ("@/views/transfer-product/TP1/new.vue")
            }, {
                path: "/transfer-product/TP1/edit",
                name: "transfer-product-TP1-edit",
                component: () => import ("@/views/transfer-product/TP1/edit.vue")
            },
            // ---
            {
                path: "/transfer-product/TP2-1",
                name: "transfer-product-TP2-1",
                component: () => import ("@/views/transfer-product/TP2-1/index.vue")
            }, {
                path: "/transfer-product/TP2-1/new",
                name: "transfer-product-TP2-1-new",
                component: () => import ("@/views/transfer-product/TP2-1/new.vue")
            }, {
                path: "/transfer-product/TP2-1/edit",
                name: "transfer-product-TP2-1-edit",
                component: () => import ("@/views/transfer-product/TP2-1/edit.vue")
            },
            // ---
            {
                path: "/transfer-product/TP2-2",
                name: "transfer-product-TP2-2",
                component: () => import ("@/views/transfer-product/TP2-2/index.vue")
            },
            // ---
            {
                path: "/transfer-product/TP3-1",
                name: "transfer-product-TP3-1",
                component: () => import ("@/views/transfer-product/TP3-1/index.vue")
            }, {
                path: "/transfer-product/TP3-1/new",
                name: "transfer-product-TP3-1-new",
                component: () => import ("@/views/transfer-product/TP3-1/new.vue")
            }, {
                path: "/transfer-product/TP3-1/edit",
                name: "transfer-product-TP3-1-edit",
                component: () => import ("@/views/transfer-product/TP3-1/edit.vue")
            },
            // ---
            {
                path: "/transfer-product/TP3-2",
                name: "transfer-product-TP3-2",
                component: () => import ("@/views/transfer-product/TP3-2/index.vue")
            },

            // report
            {
                path: "/report/summary-sales-customer-report",
                name: "summary-sales-customer-report",
                component: () => import ("@/views/report/reportSalesAndCustomer.vue")
            }, {
                path: "/report/summary-weekly-report",
                name: "summary-weekly-report",
                component: () => import ("@/views/report/reportWeekly.vue")
            }, {
                path: "/report/summary-best-seller-report",
                name: "summary-best-seller-report",
                component: () => import ("@/views/report/reportBestSeller.vue")
            }, {
                path: "/report/summary-transaction-report",
                name: "summary-transaction-report",
                component: () => import ("@/views/report/reportTransaction.vue")
            }, {
                path: "/report/summary-warehouse-report",
                name: "summary-warehouse-report",
                component: () => import ("@/views/report/reportWarehouseSummary.vue")
            },

            // setting
            {
                path: "/setting/shop-info",
                name: "shop-info",
                component: () => import ("@/views/setting/shop-info/index.vue")
            }, {
                path: "/setting/user-profile",
                name: "user-profile",
                component: () => import ("@/views/setting/user-profile/index.vue")
            }, {
                path: "/setting/change-password",
                name: "change-password",
                component: () => import ("@/views/setting/change-pass/index.vue")
            },
            // จังหวัด
            {
                path: "/config/province/index",
                name: "PROVINCE-index",
                component: () => import ("@/views/config/Province/index.vue")
            }, {
                path: "/config/province/new",
                name: "PROVINCE-new",
                component: () => import ("@/views/config/Province/new.vue")
            }, {
                path: "/config/province/edit",
                name: "PROVINCE-edit",
                component: () => import ("@/views/config/Province/edit.vue")
            },
            // อำเภอ
            {
                path: "/config/district/index",
                name: "DISTRICT-index",
                component: () => import ("@/views/config/District/index.vue")
            }, {
                path: "/config/district/new",
                name: "DISTRICT-new",
                component: () => import ("@/views/config/District/new.vue")
            }, {
                path: "/config/district/edit",
                name: "DISTRICT-edit",
                component: () => import ("@/views/config/District/edit.vue")
            },
            // ตำบล
            {
                path: "/config/subdistrict/index",
                name: "SUBDISTRICT-index",
                component: () => import ("@/views/config/SubDistrict/index.vue")
            }, {
                path: "/config/subdistrict/new",
                name: "SUBDISTRICT-new",
                component: () => import ("@/views/config/SubDistrict/new.vue")
            }, {
                path: "/config/subdistrict/edit",
                name: "SUBDISTRICT-edit",
                component: () => import ("@/views/config/SubDistrict/edit.vue")
            },


        ]
    }, {
        path: "/:pathMatch(.*)*",
        redirect: "/purchase/general/404"
    },
];

const router = createRouter({history: createWebHashHistory(), routes});

router.beforeEach(() => { // Scroll page to top on every route change
    setTimeout(() => {
        window.scrollTo(0, 0);
    }, 100);
});

export default router;
